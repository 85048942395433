import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown"; // Import ReactMarkdown
import "../styles/Writings.css";
import { WritingInfo } from "../helpers/WritingInfo"; // Adjust the import path as needed

function WritingsPage() {
  const { writingName } = useParams();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState("main");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedWriting, setSelectedWriting] = useState(null);

  useEffect(() => {
    if (writingName) {
      const writing = WritingInfo.find((w) => w.name === writingName);
      if (writing) {
        setSelectedWriting(writing);
        setSelectedCategory(writing.type);
        setCurrentView("writing");
      }
    }
  }, [writingName]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentView("category");
  };

  const handleBackClick = () => {
    if (currentView === "writing") {
      setCurrentView("category");
      navigate(`/writings`);
    } else {
      setCurrentView("main");
      navigate("/writings");
    }
  };

  const handleWritingClick = (writing) => {
    setSelectedWriting(writing);
    setCurrentView("writing");
    navigate(`/writings/${writing.name}`);
  };

  const filteredWritings = WritingInfo.filter(
    (writing) => writing.type === selectedCategory.toLowerCase()
  );

  return (
    <section className="writings">
      <div className="content">
        <div
          className={`translucentBox ${
            currentView !== "main" ? "fade-in" : "fade-out"
          }`}
        >
          {currentView === "main" ? (
            <>
              <h1 className="writingTitle">Writings</h1>
              <div className="subtitle">
                <hr className="separator" />
                <div className="categories">
                  <div
                    className="category"
                    onClick={() => handleCategoryClick("Movies")}
                  >
                    <h3 className="categoryName">Movies</h3>
                  </div>
                  <div
                    className="category"
                    onClick={() => handleCategoryClick("Personal Writings")}
                  >
                    <h3 className="categoryName">Personal Writings</h3>
                  </div>
                  <div
                    className="category"
                    onClick={() => handleCategoryClick("Poems")}
                  >
                    <h3 className="categoryName">Poems</h3>
                  </div>
                </div>
              </div>
            </>
          ) : currentView === "category" ? (
            <>
              <div className="header">
                <div className="backButton" onClick={handleBackClick}>
                  ← Back
                </div>
                <h1 className="writingTitle">{selectedCategory}</h1>
              </div>
              <div className="writingsList">
                {filteredWritings.map((writing, index) => (
                  <div
                    key={index}
                    className="writingModal"
                    onClick={() => handleWritingClick(writing)}
                  >
                    <h3 className="writingTitle">{writing.name}</h3>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="header">
                <div className="backButton" onClick={handleBackClick}>
                  ← Back
                </div>
                <h1 className="writingTitle">{selectedWriting.name}</h1>
              </div>
              <div className="writing-content">
                {selectedWriting.type === "poems"
                  ? selectedWriting.paragraphs.map((stanza, index) => (
                      <div key={index} className="stanza">
                        {stanza.map((line, lineIndex) => (
                          <p key={lineIndex} className="poem-line">
                            {line}
                          </p>
                        ))}
                      </div>
                    ))
                  : Object.values(selectedWriting.paragraphs).map(
                      (paragraph, index) => (
                        <ReactMarkdown key={index}>{paragraph}</ReactMarkdown>
                      )
                    )}
              </div>
              {selectedWriting.references && (
                <div className="writing-references">
                  <h2>References</h2>
                  <ul>
                    {selectedWriting.references.map((ref, index) => (
                      <li key={index}>{ref}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default WritingsPage;
