import React, { useEffect } from "react";
import "../styles/Home.css";

function Home() {
  useEffect(() => {
    // Set overflow to hidden when the component mounts
    document.body.style.overflow = "hidden";

    // Reset overflow to auto when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <section className="home">
      <div className="headerWrapper">
        <div className="headerContainer">
          <h1>Pay Forward</h1>
          <p>1 John 4:10-11</p>
          <p className="verse">
            "In this is love, not that we have loved God but that he{" "}
            <strong>loved us</strong> and sent his Son to be the{" "}
            <em>propitiation</em> for our sins. Beloved, if God so loved us, we
            also{" "}
            <u>
              <strong>ought</strong>
            </u>{" "}
            to love one another."
          </p>
          <div className="buttonContainer">
            <a href="/writings">
              <button>Writings</button>
            </a>
            <a href="#about">
              <button>About</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
